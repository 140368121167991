import React, { FunctionComponent, useEffect } from 'react';
import Helmet from 'react-helmet';
import { StickyContainer } from 'react-sticky';

import { HeaderLink } from '../../types/components/index.d';
import Header from '../Header';
import Footer from '../Footer';

type PageProps = {
  title?: string;
  description?: string;
  headerLink?: HeaderLink;
};

const Page: FunctionComponent<PageProps> = ({ title, description, children, headerLink }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      {(title || description) && (
        <Helmet>
          {title && <title>{title}</title>}
          {description && <meta name="description" content={description} />}
        </Helmet>
      )}

      <StickyContainer>
        <Header headerLink={headerLink} />

        {children}

        <Footer />
      </StickyContainer>
    </>
  );
};

export default Page;
