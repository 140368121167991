import React, { FunctionComponent } from 'react';
import { Field, FieldProps } from 'formik';
import parser from 'react-html-parser';
import classnames from 'classnames';

import '../styles.scss';

type RadioProps = {
  name: string;
  label: string;
  labelClassName?: string;
  value: string;
};

interface RadioFieldProps extends FieldProps {
  label: string;
  labelClassName?: string;
  value: string;
}

const RadioField: FunctionComponent<RadioFieldProps> = ({
  label,
  labelClassName,
  value,
  field,
  form,
}) => (
  <>
    <input
      type="radio"
      className="radio__input u-sr-only"
      id={value}
      name={field.name}
      value={value}
      checked={form.values[field.name] === value}
      onChange={() => form.setFieldValue(field.name, value)}
    />

    <label htmlFor={value} className={classnames('radio__label', labelClassName)}>
      {parser(label)}
    </label>

    <div
      className={classnames('radio__custom-input', {
        'is-selected': form.values[field.name] === value,
      })}
      aria-hidden="true"
      role="presentation"
    />
  </>
);

const Radio: FunctionComponent<RadioProps> = ({ name, label, labelClassName, value }) => (
  <div className="radio mb-2">
    <Field
      name={name}
      component={(props: FieldProps) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <RadioField label={label} labelClassName={labelClassName} value={value} {...props} />
      )}
    />
  </div>
);

export default Radio;
