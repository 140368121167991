import React, { createContext, useReducer, useMemo, FunctionComponent } from 'react';
import { Questionnaire } from '../../types/store/index.d';

type State = {
  completed: Questionnaire[] | null;
  riskLevel: string;
  riskScore: number;
};

type Action = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

export type StoreAction = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

const initialState: State = {
  completed: null,
  riskLevel: '',
  riskScore: 0,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'COMPLETE_QUESTIONNAIRE':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESET_QUESTIONNAIRE':
      return initialState;
    default:
      return state;
  }
};

export const QuestionnaireContext = createContext<StoreAction>({} as StoreAction);

const QuestionnaireProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <QuestionnaireContext.Provider value={value}>{children}</QuestionnaireContext.Provider>;
};

export default {
  QuestionnaireContext,
  QuestionnaireProvider,
};
