import React, { FunctionComponent } from 'react';

import LocalStorageService from '../../../services/localStorage';
import { CustomCard, Drag } from '../../Images';

type GameboardHeaderProps = {
  disableLink?: boolean;
};

const GameboardHeader: FunctionComponent<GameboardHeaderProps> = ({ disableLink }) => {
  const localStorageService = new LocalStorageService();

  const handleButtonClick = (): void => {
    localStorageService.remove('board');

    window.open('/financial-planning-goals-based-discovery-board', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="gameboard-header">
      <div className="container">
        <div className="row text-center text-md-start">
          <div className="col-12 col-md-4">
            <h1 className="text-white">
              <span className="d-block">Let&apos;s discuss your</span> <span>goals.</span>
            </h1>

            {!disableLink && (
              <button type="button" className="btn btn--light mt-4" onClick={handleButtonClick}>
                Expand
              </button>
            )}
          </div>

          <div className="col-12 col-md-4 mt-5 mt-md-3">
            <Drag />
            <p className="text-white mt-3">
              Drag and drop any of the 32 goals that you care about to the appropriate place on the
              board.
            </p>
          </div>

          <div className="col-12 col-md-4 mt-5 mt-md-3">
            <CustomCard />
            <p className="text-white mt-3">
              Create custom cards as well as skip goals you find irrelevant or unnecessary.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameboardHeader;
