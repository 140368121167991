import React, { FunctionComponent } from 'react';

import Page from '../../components/Page';
import { GameboardHeader, Gameboard } from '../../components/Gameboard';

const GameboardPage: FunctionComponent = () => (
  <Page
    title="SEI | Financial Planning Tools | Discovery Board"
    description="A digital financial planning tool for advisors. SEI's goals-based discovery board allows advisors and investors to collaborate and connect. The tool helps you identify, clarify and prioritize financial goals for your clients. Building better financial futures, together."
  >
    <div className="gameboard-wrapper bg-aqua">
      <GameboardHeader disableLink />
      <Gameboard />
    </div>
  </Page>
);

export default GameboardPage;
