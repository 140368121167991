import React, { FunctionComponent } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import { Card } from '../../../types/components/index.d';

import GameboardSortableCard from '../SortableCard';

import './styles.scss';

type GameboardListProps = {
  items: Card[];
  listName: string;
  onSkip?: (sourceLocation?: string, sourceIndex?: number) => void;
};

const GameboardList: FunctionComponent<GameboardListProps> = ({ items, listName, onSkip }) => {
  const getCardAction = (): string | React.ReactElement => {
    if (listName === 'default') {
      return 'Skip Card';
    }

    if (listName === 'skipped') {
      return 'Return to Deck';
    }

    return <div className="gameboard-card__action-icon" />;
  };

  const cardAction = getCardAction();

  return (
    <Droppable droppableId={listName}>
      {(provided, snapshot) => (
        <div className={classNames('gameboard__list', { 'is-dragging': snapshot.isDraggingOver })}>
          <div className="gameboard__cards" ref={provided.innerRef}>
            {items.map((item, index) => (
              <GameboardSortableCard
                key={item.id}
                item={item}
                index={index}
                cardAction={cardAction}
                sourceLocation={listName}
                onSkip={onSkip}
              />
            ))}

            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default GameboardList;
