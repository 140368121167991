import React, { FunctionComponent } from 'react';
import { Formik, Form } from 'formik';
import { AnySchema } from 'yup';
import '../styles.scss';

type InitialValuesList = Record<string, unknown>;

type FormProps = {
  initialValues: InitialValuesList;
  validationSchema?: AnySchema;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: any, { setSubmitting }: any) => void;
};

const FormWrapper: FunctionComponent<FormProps> = ({
  initialValues,
  validationSchema,
  onSubmit,
  children,
}) => (
  <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
    <Form className="form">
      <fieldset>{children}</fieldset>
    </Form>
  </Formik>
);

export default FormWrapper;
