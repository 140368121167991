import React, { FunctionComponent } from 'react';

import './styles.scss';

const Legend: FunctionComponent = () => (
  <div className="d-flex flex-column align-items-center mt-3">
    <div>
      <div className="gameboard__legend gameboard__legend--financial">
        We can estimate the cost of this goal.
      </div>
      <div className="gameboard__legend gameboard__legend--life">
        We can&apos;t put a price tag on this goal.
      </div>
    </div>
  </div>
);

export default Legend;
