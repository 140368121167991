import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Sticky } from 'react-sticky';

import { ContactForm } from '../Form/Types';
import { HeaderLink } from '../../types/components/index.d';
import Modal from '../Modal';
import LogoHeader from '../Images/Icons/LogoHeader';

import './styles.scss';

type HeaderProps = {
  printView?: boolean;
  headerLink?: HeaderLink;
};

const DefaultHeaderLink: HeaderLink = {
  link: '/risk-tolerance',
  label: 'Risk Tolerance',
};

const Header: FunctionComponent<HeaderProps> = ({ printView, headerLink = DefaultHeaderLink }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = (): void => setIsModalOpen(!isModalOpen);

  return (
    <>
      {printView ? (
        <header className="site-header__container">
          <div className="container container--max-1300">
            <div className="site-header__inner">
              <div className="site-header__logo">
                <a href="https://seic.com/">
                  <LogoHeader />
                </a>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <>
          <Sticky topOffset={80}>
            {({ style }) => (
              <header style={style} className="site-header__container">
                <div className="container container--max-1300">
                  <div className="site-header__inner">
                    <div className="site-header__logo">
                      <a href="https://seic.com/">
                        <LogoHeader />
                      </a>
                    </div>

                    <nav className="site-header__links" aria-label="Main Navigation">
                      <Link to={headerLink.link} className="link link--primary site-header__link">
                        <span>
                          <strong>{headerLink.label}</strong>
                        </span>
                      </Link>

                      <button
                        className="btn btn--dark site-header__link"
                        type="button"
                        onClick={toggleModal}
                      >
                        Connect with us
                      </button>
                    </nav>
                  </div>
                </div>
              </header>
            )}
          </Sticky>

          <Modal
            isOpen={isModalOpen}
            header="We look forward to speaking with you!"
            closeModal={toggleModal}
          >
            <ContactForm />
          </Modal>
        </>
      )}
    </>
  );
};

export default Header;
