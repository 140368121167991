import React, { FunctionComponent } from 'react';
import Modal from '../../../Modal';
import { Form, Input } from '../..';

import { CustomCardValues } from '../../../../types/components/index.d';

import './styles.scss';

type CustomCardProps = {
  isOpen: boolean;
  toggleModal: () => void;
  onSave: (values: CustomCardValues) => void;
};

const CustomCard: FunctionComponent<CustomCardProps> = ({ isOpen, toggleModal, onSave }) => {
  const initialValues: CustomCardValues = {
    customCard: '',
  };

  const handleSubmission = (values: CustomCardValues): void => {
    onSave(values);

    toggleModal();
  };

  return (
    <>
      <Modal header="Add custom card" isOpen={isOpen} closeModal={toggleModal}>
        <p className="modal__lede text-center mb-4">
          Short supporting copy that details the custom card.
        </p>

        <div className="form-wrapper">
          <Form initialValues={initialValues} onSubmit={handleSubmission}>
            <Input name="customCard" label="Custom card" required />

            <div className="d-flex mt-2">
              <button className="btn btn--light me-3" type="button" onClick={toggleModal}>
                Cancel
              </button>

              <input type="Submit" defaultValue="Save card" />
            </div>
          </Form>
        </div>
      </Modal>

      <button className="btn btn--dark" type="button" onClick={toggleModal}>
        Create custom card
      </button>
    </>
  );
};

export default CustomCard;
