import React, { FunctionComponent, useState } from 'react';

import { ContactForm } from '../Form/Types';
import Modal from '../Modal';

import './styles.scss';

const FooterCTA: FunctionComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = (): void => setIsModalOpen(!isModalOpen);

  return (
    <>
      <div className="footer-cta">
        <h1 className="text-center footer-cta__heading">
          Get one step closer to your financial goals today.
        </h1>

        <div className="text-center mt-5">
          <button className="btn btn--dark" type="button" onClick={toggleModal}>
            Get started
          </button>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        header="We look forward to speaking with you!"
        closeModal={toggleModal}
      >
        <ContactForm />
      </Modal>
    </>
  );
};

export default FooterCTA;
