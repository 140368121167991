import React, { FunctionComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from './Home';
import Gameboard from './Gameboard';
import Questionnaire from './RiskTolerance/Questionnaire';
import Results from './RiskTolerance/Results';
import Print from './Print';

const App: FunctionComponent = () => (
  <div className="App">
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>

      <Route path="/financial-planning-goals-based-discovery-board" exact>
        <Gameboard />
      </Route>

      <Route path="/risk-tolerance" exact>
        <Questionnaire />
      </Route>

      <Route path="/risk-tolerance/results" exact>
        <Results />
      </Route>

      <Route path="/print">
        <Print />
      </Route>

      <Redirect to="/" push />
    </Switch>
  </div>
);

export default App;
