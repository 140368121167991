import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { useQuestionnaireStore } from '../../../hooks';
import { QuestionnaireFormValues } from '../../../types/components/index.d';
import QuestionnaireForm from '../../../components/Form/Types/Questionnaire';

import Page from '../../../components/Page';
import { RiskToleranceHero } from '../../../components/Hero';
import Gauge from '../../../components/Gauge';

import '../styles.scss';

const headerLink = {
  link: '/',
  label: 'Back to Gameboard',
};

const RiskToleranceQuestionnaireResults: FunctionComponent = () => {
  const resultsRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { state, dispatch } = useQuestionnaireStore();
  const { riskLevel, riskScore, completed } = state;
  const [formSubmitMessage, setFormSubmitMessage] = useState('');

  const getCompletedQuestionnaire = (): string => {
    const questions = completed?.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item: any) =>
        `<div>
        <p style="font-size: 16px;">
          ${item.id}. ${item.question}
        </p>
        <p style="font-size: 16px; font-weight: 700; padding-left: 25px;">${item.selectedAnswer.label}</p>
      </div>`,
    );
    return questions ? questions.join('').toString() : '';
  };

  const resetForm = (): void => {
    dispatch({
      type: 'RESET_QUESTIONNAIRE',
    });

    history.push('/risk-tolerance');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (values: QuestionnaireFormValues): Promise<any> => {
    const { fullName, advisorEmail, email } = values;
    const completedQuestionnaire = getCompletedQuestionnaire();
    const disclosure = `
      <div style="font-size: 14px; margin: 15px 0; padding: 20px; border: solid 1px black; text-align: center;">
        <p style="margin: 0">Questions?</p>
        <p style="margin: 0">Contact <a href="mailto:advisorinfo@seic.com">advisorinfo@seic.com</a> or call 888-734-2679</p>
      </div>
      <hr style="margin: 15px 0;">
      <p style="font-size: 14px;">
        The SEI Discovery Board and Risk Tolerance Questionnaire are the business names for the Goals
        Based Discovery interactive tools provided by SEI Investments Management Corporation (SIMC), a
        registered investment advisor. Usage of these tools is for illustrative purposes only and does
        not constitute advice or a recommendation. SIMC is a wholly owned subsidiary of SEI
        Investments Company.
      </p>
      <p style="font-size: 14px;">
        If Risk Tolerance is required by SEI, we have incorporated the Risk Tolerance Questionnaire
        within the Account Application. This tool will not be accepted in lieu of the required Risk
        Tolerance Questionnaire contained within the Account Application.
      </p>
      <p style="font-size: 14px;">
        Investing involves risk including possible loss of principal. There can be no guarantee
        objectives will be achieved. Due to the ever changing nature of investing, be sure to review
        your investment decisions periodically to be sure they are still consistent with your goals.
      </p>
      <p style="font-size: 14px;">Your financial advisor is not affiliated with SEI or its subsidiaries</p>`;
    const emailLede = `
      <p style="font-size: 16px;">Risk Tolerance Questionnaire Responses:</p>
      <p style="font-size: 27px; margin: 25px 0;">${fullName}'s risk level is <span style="font-size: 27px; font-weight: 700;">${riskLevel}</span>, and risk score is <span style="font-size: 27px; font-weight: 700;">${riskScore}</span>.</p>`;
    const message = `${emailLede}${completedQuestionnaire}${disclosure}`;
    await axios
      .post('/.netlify/functions/sendmail', {
        message,
        fullName,
        email,
        advisorEmail,
      })
      .then(() => {
        const submitMessage =
          values.advisorEmail === ''
            ? 'Great! An email have been sent to you.'
            : 'Great! Emails have been sent to you and your advisor.';
        setFormSubmitMessage(submitMessage);
      })
      .catch(() => {
        setFormSubmitMessage('There was an error submitting your form.');
      });
  };

  useEffect(() => {
    if (resultsRef?.current) {
      window.scrollTo(0, resultsRef?.current?.offsetTop - 100);
    }
  });

  return (
    <Page
      headerLink={headerLink}
      description="Example metadata for Risk Tolerance Questionnaire page"
    >
      <RiskToleranceHero />
      <div ref={resultsRef}>
        <Gauge percentValue={riskScore} title="Risk gauge assessment" />
      </div>
      {riskLevel && riskScore && (
        <div className="h3 text-center results__score">
          Your risk level is {riskLevel}, Your risk score is {riskScore}
        </div>
      )}
      <div className="text-center results__reset">
        <button type="button" className="btn btn--dark" onClick={resetForm}>
          Reset form
        </button>
      </div>
      <div className="results__form">
        <div className="results__form-wrapper">
          {!formSubmitMessage ? (
            <>
              <div className="h3 text-center results__form-heading">
                Would you like to email this to yourself or financial advisor?
              </div>
              <QuestionnaireForm onSubmit={handleSubmit} />
            </>
          ) : (
            <div className="h3 text-center">{formSubmitMessage}</div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default RiskToleranceQuestionnaireResults;
