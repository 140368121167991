/* eslint-disable class-methods-use-this */
class LocalStorageService {
  get(name: string): JSON | null {
    const data = localStorage.getItem(name);

    if (!data) {
      return null;
    }

    return JSON.parse(data);
  }

  set(name: string, data: unknown): void {
    const localStorageData = JSON.stringify(data);

    localStorage.setItem(name, localStorageData);
  }

  remove(name: string): void {
    localStorage.removeItem(name);
  }

  clear(): void {
    localStorage.clear();
  }
}

export default LocalStorageService;
