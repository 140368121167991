import React, { FunctionComponent } from 'react';
import { Card } from '../../../types/components/index.d';
import { Close, LegendLife, LegendFinancial } from '../../Images';

import './styles.scss';

type GameboardCardProps = {
  item: Card;
  style?: React.CSSProperties;
  index?: number;
  sourceLocation?: string;
  cardAction?: string | React.ReactElement;
  onSkip?: (sourceLocation?: string, sourceIndex?: number) => void;
};

const GameboardCard: FunctionComponent<GameboardCardProps> = ({
  style,
  item,
  index,
  sourceLocation,
  cardAction,
  onSkip,
}) => (
  <div className="card-item gameboard-card d-flex justify-content-between" style={style}>
    {item.category && (
      <div className="gameboard-card__legend">
        {item.category === 'life' ? <LegendLife /> : <LegendFinancial />}
      </div>
    )}
    <div className="d-flex justify-content-start align-items-center w-100 mr-2 pr-1">
      <div className="gameboard-card__label">{item.label}</div>
    </div>

    {onSkip && (
      <span
        onClick={() => onSkip(sourceLocation, index)}
        onKeyPress={() => onSkip(sourceLocation, index)}
        tabIndex={0}
        role="button"
        aria-label={cardAction === 'Skip Card' ? 'Skip Card' : 'Return to Deck'}
        className="text-left gameboard-card__action mr-1"
      >
        {typeof cardAction === 'string' ? (
          cardAction
        ) : (
          <div key={0} className="gameboard-card__action-icon">
            <Close size={10} className="gameboard-card__svg" />
          </div>
        )}
      </span>
    )}
  </div>
);

export default GameboardCard;
