type QuadrantSignature = {
  [index: string]: string;
};

export const quadrants = [
  {
    id: 'wantToNow',
    label: 'Want to Now',
    text: 'Things that are important but not essential in the next 5 years.',
  },
  {
    id: 'wantToLater',
    label: 'Want to Later',
    text: 'Things you want but are willing to wait for.',
  },
  {
    id: 'haveToNow',
    label: 'Have to Now',
    text: 'Goals that need to be reached in the next 5 years.',
  },
  {
    id: 'haveToLater',
    label: 'Have to Later',
    text: 'Long-term goals that can wait longer than 5 years.',
  },
];

export const droppableIdToQuadrantId: QuadrantSignature = {
  default: 'default',
  wantToLater: 'wantToLater',
  wantToNow: 'wantToNow',
  haveToNow: 'haveToNow',
  haveToLater: 'haveToLater',
  skipped: 'skipped',
  skippedHeader: 'skipped',
};

export const modalData = [
  {
    id: 1,
    listName: 'wantToNow',
    modalHeader: 'Want To Now',
    modalBody: '<p>Things that are important but not essential in the next 5 years.</p>',
  },
  {
    id: 2,
    listName: 'wantToLater',
    modalHeader: 'Want To Later',
    modalBody: '<p>Things you want but are willing to wait for.</p>',
  },
  {
    id: 3,
    listName: 'haveToNow',
    modalHeader: 'Have To Now',
    modalBody: '<p>Goals that need to be reached in the next 5 years.</p>',
  },
  {
    id: 4,
    listName: 'haveToLater',
    modalHeader: 'Have To Later',
    modalBody: '<p>Long-term goals that can wait longer than 5 years.</p>',
  },
];

export default {
  quadrants,
  droppableIdToQuadrantId,
  modalData,
};
