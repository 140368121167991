import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import './styles.scss';

type BlockProps = {
  title: string;
  description: string;
  link?: {
    url: string;
    label?: string;
    download?: boolean;
  };
  color?: string;
};

const Block: FunctionComponent<BlockProps> = ({ title, description, link, color }) => (
  <div className={classNames('block', color && `block--${color}`)}>
    <h5 className="mb-4">{title}</h5>
    <p>{description}</p>
    {link && link.download && (
      <a href={link.url} className="mt-4 btn btn--dark" target="_blank" rel="noreferrer">
        {link.label}
      </a>
    )}
    {link && !link.download && (
      <a href={link?.url} className="mt-4 btn btn--dark">
        {link.label}
      </a>
    )}
  </div>
);

export default Block;
