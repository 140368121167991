import React, { FunctionComponent, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Slider from 'react-slick';

import { Card } from '../../../types/components/index.d';

import GameboardSortableCard from '../SortableCard';
import GameboardCard from '../Card';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

type GameboardCardSliderProps = {
  items: Card[];
  listName: string;
  onUpdate: (index: number) => void;
};

const GameboardCardSlider: FunctionComponent<GameboardCardSliderProps> = ({
  items,
  listName,
  onUpdate,
}) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = (index: number): void => {
    setActiveSlideIndex(index);

    onUpdate(index);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    useTransform: true,
    afterChange: handleSlideChange.bind(this),
  };

  const activeItem = items[activeSlideIndex];

  return (
    <>
      <div className="card-slider">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Slider {...settings}>
          {items.map((item) => (
            <div key={item.id}>
              <GameboardCard item={item} />
            </div>
          ))}
        </Slider>

        {activeItem && (
          <Droppable droppableId={listName} direction="horizontal" isDropDisabled>
            {(provided) => (
              <div className="card-slider__drag" ref={provided.innerRef}>
                <GameboardSortableCard
                  item={activeItem}
                  index={activeSlideIndex}
                  sourceLocation="tray"
                />

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </div>
    </>
  );
};

export default GameboardCardSlider;
