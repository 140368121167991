import React, { FunctionComponent } from 'react';

type QuestionImage = {
  src: string;
  alt: string;
};

type QuestionProps = {
  order: number;
  question: string;
  image?: QuestionImage | null;
};

const Question: FunctionComponent<QuestionProps> = ({ order, question, image }) => (
  <>
    <h5 className="mb-3">
      {order}. {question}
    </h5>
    {image && <img className="center my-5" src={image.src} alt={image.alt} />}
  </>
);

export default Question;
