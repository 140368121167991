import React, { FunctionComponent } from 'react';
import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';

import { Card } from '../../../types/components/index.d';

import GameboardCard from '../Card';

import './styles.scss';

type GameboardSortableCardProps = {
  item: Card;
  index: number;
  sourceLocation?: string;
  cardAction?: string | React.ReactElement;
  onSkip?: (sourceLocation?: string, sourceIndex?: number) => void;
};

const GameboardSortableCard: FunctionComponent<GameboardSortableCardProps> = ({
  item,
  index,
  sourceLocation,
  cardAction,
  onSkip,
}) => {
  const getItemStyle = (isDragging: boolean): React.CSSProperties => {
    const styles: React.CSSProperties = {};

    if (isDragging) {
      styles.opacity = 1;
    }

    return styles;
  };

  const getContainerStyle = (
    style: DraggingStyle | NotDraggingStyle | undefined,
    snapshot: DraggableStateSnapshot,
  ): React.CSSProperties | undefined => {
    if (!snapshot.isDropAnimating) {
      return style;
    }

    return {
      ...style,
      transform: 'none',
      transitionDuration: '0.001s',
    };
  };

  const listItem = (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
  ): React.ReactElement => (
    <div
      className="gameboard-sortable-card"
      ref={provided.innerRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...provided.draggableProps}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...provided.dragHandleProps}
      style={getContainerStyle(provided.draggableProps.style, snapshot)}
    >
      <GameboardCard
        style={getItemStyle(snapshot.isDragging)}
        item={item}
        index={index}
        sourceLocation={sourceLocation}
        cardAction={cardAction}
        onSkip={onSkip}
      />
    </div>
  );

  return (
    <Draggable draggableId={`${item.id}`} index={index}>
      {listItem}
    </Draggable>
  );
};

export default GameboardSortableCard;
