import React, { FunctionComponent } from 'react';
import ReactModal from 'react-modal';
import { Close } from '../Images';

import './styles.scss';

type ModalProps = {
  header?: string;
  isOpen: boolean;
  closeModal: () => void;
};

const Modal: FunctionComponent<ModalProps> = ({ header, children, isOpen, closeModal }) => {
  ReactModal.setAppElement('#root');

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="app-modal"
      overlayClassName="app-modal__overlay"
      contentLabel="Example Modal"
    >
      <div className="app-modal__inner">
        <div className="app-modal__form-wrapper">
          <div className="app-modal__overflow">
            <div
              className="app-modal__content app-modal__content--form is-visible"
              data-modal="content-form"
            >
              <h2 className="app-modal__header fw-bold" id="demo-modal-header" data-modal="heading">
                {header}
              </h2>
              <div className="app-modal__lede">{children}</div>
            </div>

            <div className="app-modal__close-button">
              <button type="button" onClick={closeModal}>
                <Close />
                <span className="u-sr-only">Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;
