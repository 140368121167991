import React, { FunctionComponent } from 'react';

import { Radio } from '../../Form';

type AnswerProps = {
  questionId: number;
  order: number;
  answer: string;
};

const Answer: FunctionComponent<AnswerProps> = ({ questionId, order, answer }) => {
  const renderAlphabet = (): string => {
    if (order > 26) {
      return '';
    }

    return (order + 9).toString(36).toUpperCase();
  };

  return (
    <Radio
      name={`question${questionId}`}
      label={`<span class="fw-bold me-2">${renderAlphabet()}.</span>${answer}`}
      value={`${questionId}-${renderAlphabet()}`}
    />
  );
};

export default Answer;
