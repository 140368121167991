import React, { FunctionComponent } from 'react';
import dayjs from 'dayjs';

import LogoFooter from '../Images/Icons/LogoFooter';
import Disclosure from '../Disclosure';

import './styles.scss';

const Footer: FunctionComponent = () => {
  return (
    <footer className="site-footer__container">
      <div className="site-footer__disclosures py-5">
        <div className="container container--max-1300">
          <Disclosure />
        </div>
      </div>
      <div className="site-footer__links-wrapper container container--max-1300">
        <div className="site-footer__inner">
          <div className="site-footer__logo mb-4 mb-sm-0">
            <a href="https://seic.com/">
              <LogoFooter />
            </a>
          </div>
          <div className="site-footer__content">
            <nav className="site-footer__nav" aria-label="Legal Pages Navigation">
              <a href="https://seic.com/cookie-policy" className="site-footer__link">
                Cookie Policy
              </a>
              <a href="https://seic.com/privacy-policy" className="site-footer__link">
                Privacy Policy
              </a>
              <a href="https://seic.com/terms-use" className="site-footer__link">
                Terms of Use
              </a>
            </nav>
            <div className="site-footer__copyright">
              <sup>&copy;</sup>2009-{dayjs().year()} SEI
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
