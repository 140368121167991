import React, { FunctionComponent } from 'react';
import SEIGameboardFAQs from '../../images/SEI_Gameboard_FAQs.pdf';
import Block from '../Block';
import './styles.scss';

const InfoBlocks: FunctionComponent = () => (
  <div className="info-blocks">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 mb-4 mb-md-0">
          <Block
            title="Step-by-Step Directions"
            description="Let us help guide you through the process."
            link={{
              url: SEIGameboardFAQs,
              label: 'Download',
              download: true,
            }}
            color="orange"
          />
        </div>
        <div className="col-12 col-md-6">
          <Block
            title="Risk Tolerance Questionnaire"
            description="A robust discovery process isn't just about identifying goals. It's equally important to understand your tolerance for volatility and risk."
            link={{ url: '/risk-tolerance', label: 'Take the next step' }}
            color="teal"
          />
        </div>
      </div>
    </div>
  </div>
);

export default InfoBlocks;
