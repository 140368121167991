import React, { FunctionComponent } from 'react';

type CloseProps = {
  className?: string;
  size?: number;
};

const Close: FunctionComponent<CloseProps> = ({ size = 22, className }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
    >
      <path d="M1 1L21 21" stroke="currentColor" strokeWidth="2" />
      <path d="M21 1L1 21" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

export default Close;
