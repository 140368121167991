import React, { FunctionComponent } from 'react';
import numeral from 'numeral';

import './styles.scss';

type GaugeProps = {
  percentValue: number;
  useFloat?: boolean;
  size?: string;
  title?: string;
};

const Gauge: FunctionComponent<GaugeProps> = ({ percentValue, useFloat = false, size, title }) => {
  const percentNum = numeral(percentValue).value() || percentValue;
  const calculatedPercentValue = useFloat
    ? Math.min(percentNum, 1)
    : Math.min(Math.round(percentNum) / 100, 1);

  const rotationDegree = Math.min(Math.max(0, calculatedPercentValue * 180), 180);
  const maskStyle = {
    transform: `rotate(${rotationDegree}deg) translate3d(0,0,0)`,
  };
  const pointerStyle = {
    transform: `translateX(-50%) rotate(${rotationDegree}deg)`,
  };

  const getStatusClass = (percentage: number): string => {
    if (percentage < 0.33) {
      return 'alert';
    }
    if (percentage < 0.69) {
      return 'warning';
    }

    return 'good';
  };

  return (
    <>
      {title && <div className="h3 text-center gauge__title">{title}</div>}
      <div className="gauge__wrapper">
        <div
          className={`gauge gauge--${getStatusClass(calculatedPercentValue)} ${
            size ? `gauge--${size}` : 'gauge--lg'
          }`}
        >
          <div className="gauge__label gauge__label--alert">0-32</div>
          <div className="gauge__label gauge__label--warning">32-69</div>
          <div className="gauge__label gauge__label--good">69-100</div>
          <div className="gauge__legend">
            <div className="gauge__legend__section gauge__legend__section--alert" />
            <div className="gauge__legend__section gauge__legend__section--warning" />
            <div className="gauge__legend__section gauge__legend__section--good" />
          </div>

          <div className="gauge__image">
            <div className="gauge__arc" />
            <div className="gauge__mask" style={maskStyle} />
          </div>

          <div className="gauge__pointer" style={pointerStyle} />
        </div>
      </div>
    </>
  );
};

export default Gauge;
