import question3Chart from '../images/rtq-question3-chart.jpg';

export default [
  {
    id: 1,
    question: 'What best describes your current situation?',
    weight: 0.05,
    answers: [
      {
        label:
          'Income and expenses are expected to rise and investable assets are accumulating (e.g., early career)',
        score: 90,
      },
      {
        label:
          'Income and expenses are relatively steady and savings are growing modestly (e.g., mid/late career)',
        score: 30,
      },
      {
        label:
          'Income and expenses are relatively steady and savings are growing significantly (e.g., mid/late career)',
        score: 60,
      },
      {
        label:
          'Income and expenses are declining and/or savings are being used to maintain desired lifestyle (e.g., retirement)',
        score: 15,
      },
    ],
    image: null,
  },
  {
    id: 2,
    question: 'What is your greatest concern?',
    weight: 0.05,
    answers: [
      {
        label:
          'Not growing my assets significantly over time; I am willing to assume higher risk for higher return potential',
        score: 90,
      },
      {
        label: 'Losing money in a market downturn along the way',
        score: 60,
      },
      {
        label:
          'Losing more money than a certain amount within a given time frame (e.g., retirement)',
        score: 30,
      },
      {
        label: 'Not having certainty around achieving my wealth goal in the remaining time',
        score: 15,
      },
    ],
    image: null,
  },
  {
    id: 3,
    question:
      'Each year, the value of your portfolio fluctuates as markets change. If you invested $500,000, which of the following portfolios below would you choose?',
    weight: 0.25,
    answers: [
      {
        label:
          'Portfolio 1, which ranges from $540,000 or 8% under very good market conditions, or $485,000 or -3% under poor market conditions',
        score: 15,
      },
      {
        label:
          'Portfolio 2, which ranges from $610,000 or 22% under very good market conditions, or $435,000 or -13% under poor market conditions',
        score: 50,
      },
      {
        label:
          'Portfolio 3, which ranges from $695,000 or 39% under very good market conditions, or $365,000 or -27% under poor market conditions',
        score: 90,
      },
    ],
    image: {
      src: question3Chart,
      alt: 'A chart showing year one range of potential values',
    },
  },
  {
    id: 4,
    question:
      'In addition to the information already provided with respect to your preferences for certain investment types, what describes you best?',
    weight: 0.05,
    answers: [
      {
        label: 'I am a long-term investor focused on growing my assets',
        score: 90,
      },
      {
        label:
          'I want to plan long-term but have a hard time shrugging off moderate-to-severe losses',
        score: 60,
      },
      {
        label: 'I need stable cash flows to meet my living expenses',
        score: 30,
      },
      {
        label: `I am focused on preserving capital; I don't mind if this approach sacrifices return potential`,
        score: 15,
      },
    ],
    image: null,
  },
  {
    id: 5,
    question:
      'If I look at my account statement and there is a moderate loss, my primary reaction is?',
    weight: 0.05,
    answers: [
      {
        label:
          'Do nothing, I lost money and am unhappy but am willing to stick with it until a recovery',
        score: 60,
      },
      {
        label: `Sell all of the investment; I don't like losses`,
        score: 15,
      },
      {
        label: 'Buy more; I am okay with temporary losses, because they present opportunities',
        score: 90,
      },
      {
        label: `Sell half; I can't lose all my money`,
        score: 30,
      },
    ],
    image: null,
  },
  {
    id: 6,
    question:
      'If your portfolio experienced a market correction declining 10%, how would you feel?',
    weight: 0.05,
    answers: [
      {
        label: 'Anxious',
        score: 15,
      },
      {
        label: 'Concerned, but understand that the market does fluctuate over time',
        score: 30,
      },
      {
        label: 'Indifferent; I am focused on long-term growth',
        score: 60,
      },
      {
        label: 'Fine; short-term fluctuations do not concern me in the least',
        score: 90,
      },
    ],
    image: null,
  },
  {
    id: 7,
    question: 'From what source was this proposed account obtained?',
    weight: 0.05,
    answers: [
      {
        label: 'Investment activities, personally taking market risks',
        score: 90,
      },
      {
        label: 'Entrepreneurial activities, personally taking business risks',
        score: 60,
      },
      {
        label: 'Inheritance or other large, one-time payment',
        score: 30,
      },
      {
        label: 'Accumulated savings over a long period of secure employment',
        score: 15,
      },
    ],
    image: null,
  },
  {
    id: 8,
    question:
      'If this proposed account suffered a temporary decline, could you cover your immediate cash-flow (i.e., expenses) needs from other sources of assets?',
    weight: 0.05,
    answers: [
      {
        label:
          'No. There are no other assets that I could use to cover my immediate cash-flow needs',
        score: 15,
      },
      {
        label:
          'Yes. I have other assets that I could use to cover my immediate cash-flow needs, but it would be difficult to access them',
        score: 30,
      },
      {
        label: 'Yes. I have other assets that I could use to cover my immediate cash-flow needs',
        score: 60,
      },
      {
        label: 'This proposed account is not expected to fund any spending',
        score: 90,
      },
    ],
    image: null,
  },
  {
    id: 9,
    question: 'Select your time horizon',
    weight: 0.35,
    answers: [
      {
        label: 'Less than 3 years',
        score: 0,
      },
      {
        label: '3-8 years',
        score: 15,
      },
      {
        label: '9-15 years',
        score: 30,
      },
      {
        label: '16-20 years',
        score: 60,
      },
      {
        label: 'Greater than 20 years',
        score: 90,
      },
    ],
    image: null,
  },
  {
    id: 10,
    question: 'How important is it for you to achieve the investment objective for these assets?',
    weight: 0.05,
    answers: [
      {
        label: 'Highly important; this is a number-one priority',
        score: 15,
      },
      {
        label: 'Medium importance; I would like to do this, but can live without it',
        score: 50,
      },
      {
        label: 'Less important; this is not necessary',
        score: 90,
      },
    ],
    image: null,
  },
];
