import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';

import questions from '../../../data/risk-tolerance';
import { QuestionnaireValues } from '../../../types/components/index.d';
import { useQuestionnaireStore } from '../../../hooks';

import { Form } from '../../Form';
import Question from '../Question';
import Answer from '../Answer';

import '../styles.scss';

const Questionnaire: FunctionComponent = () => {
  const history = useHistory();
  const { dispatch } = useQuestionnaireStore();
  const [errors, setErrors] = useState<string[] | string | null>(null);

  const initialValues: QuestionnaireValues = {
    question1: '',
    question2: '',
    question3: '',
    question4: '',
    question5: '',
    question6: '',
    question7: '',
    question8: '',
    question9: '',
    question10: '',
  };
  const isQuestionIncomplete = (id: string): boolean => {
    const errorItem = Array(errors)?.find((error) => error?.includes(id));

    return !!errorItem;
  };

  const handleSubmission = (values: QuestionnaireValues): void => {
    // Reset errors
    setErrors(null);

    const incompleteQuestions = Object.entries(values).reduce(
      (accumulator: string[], [key, value]) => {
        if (value) {
          return accumulator;
        }

        accumulator.push(key);

        return accumulator;
      },
      [],
    );

    if (incompleteQuestions.length) {
      setErrors(incompleteQuestions);
    } else {
      const completedQuestions = Object.values(values).map((item) => {
        const parsedQuestionSelection = item.split('-');
        const questionId = parseInt(parsedQuestionSelection[0], 10);
        const selectedAnswer = parsedQuestionSelection[1].toLowerCase().charCodeAt(0) - 97;

        const questionItem = questions.find((question) => question.id === questionId);
        const answerItem = questionItem?.answers[selectedAnswer];

        return {
          id: questionItem?.id,
          question: questionItem?.question,
          weight: questionItem?.weight,
          selectedAnswer: answerItem,
        };
      });

      const riskScore = completedQuestions.reduce((accumulator, current) => {
        const weight = current?.weight || 0;
        const score = current?.selectedAnswer?.score || 0;

        return accumulator + weight * score;
      }, 0);
      let riskLevel = '';

      if (riskScore >= 9 && riskScore < 32) {
        riskLevel = 'Low';
      } else if (riskScore >= 32 && riskScore < 69) {
        riskLevel = 'Moderate';
      } else if (riskScore >= 69 && riskScore <= 90) {
        riskLevel = 'High';
      } else {
        setErrors('Error calculating risk: risk score out of range');
      }

      dispatch({
        type: 'COMPLETE_QUESTIONNAIRE',
        payload: {
          completed: completedQuestions,
          riskLevel,
          riskScore,
        },
      });

      window.scrollTo(0, 0);
      history.push('/risk-tolerance/results');
    }
  };

  return (
    <div className="questionnaire container">
      <h4 className="text-center mb-5">
        Please make your selections for each of these elements below.
      </h4>

      <Form initialValues={initialValues} onSubmit={handleSubmission}>
        {questions.map((questionItem) => (
          <div key={questionItem.id} className="questionnaire__question mb-5">
            <Question
              question={questionItem.question}
              order={questionItem.id}
              image={questionItem.image}
            />

            <ul className="list-unstyled">
              {questionItem.answers.map((answerItem, index) => (
                <li key={index}>
                  <Answer
                    questionId={questionItem.id}
                    order={index + 1}
                    answer={answerItem.label}
                  />
                </li>
              ))}
            </ul>

            {isQuestionIncomplete(`question${questionItem.id}`) && (
              <div className="mt-3">
                <span className="error">Required</span>
              </div>
            )}
          </div>
        ))}

        <div className="questionnaire__footer">
          <button type="submit" className="btn btn--dark">
            Display Results
          </button>

          {errors && (
            <div className="mt-3">
              <span className="error">
                Not all questions have been answered. Please complete the questionnaire before
                submitting it.
              </span>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default Questionnaire;
