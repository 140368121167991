import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { QuestionnaireStore } from './store';

import reportWebVitals from './reportWebVitals';
import App from './containers/App';

import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import './styles/styles.scss';

const tagManagerArgs = {
  gtmId: 'GTM-5WGLPBW',
  dataLayer: {
    seic: {
      sectionData: {
        IASGamboard: '',
        Appseic: '',
      },
      typeData: {
        LandingPage: '',
        'subtype(Campaign)': '',
      },
      businessUnits: 'BU Name(Advisor Network',
    },
  },
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <QuestionnaireStore.QuestionnaireProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QuestionnaireStore.QuestionnaireProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
