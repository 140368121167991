import React, { FunctionComponent } from 'react';
import { Field, FieldProps } from 'formik';
import classnames from 'classnames';

import { camilize } from '../helper';

import '../styles.scss';

type InputProps = {
  name: string;
  label?: string;
  labelClass?: string;
  placeholder?: string;
  required: boolean;
};

const InputField: FunctionComponent<FieldProps> = ({ field, form: { touched, errors } }) => {
  const hasTouched = touched[field.name];
  const hasError = hasTouched && errors[field.name];

  return (
    <>
      <div>
        <input
          className={classnames('input', {
            'input--error': hasError,
          })}
          value={field.value}
          name={field.name}
          multiple={field.multiple}
          checked={field.checked}
          onChange={field.onChange}
          onBlur={field.onBlur}
        />
      </div>

      <span className={classnames('error', hasError ? 'show' : 'hide')}>{errors[field.name]}</span>
    </>
  );
};

const Input: FunctionComponent<InputProps> = ({
  name,
  label,
  labelClass,
  placeholder,
  required,
}) => {
  const uniqueIdentifier = `input-${name.replace(' ', '-').toLowerCase()}`;

  return (
    <div className="mb-2">
      <label htmlFor={uniqueIdentifier} className={classnames('label', labelClass)}>
        {label || name}
        {required && <span className="required">*</span>}
      </label>

      <Field
        className="item-input"
        id={uniqueIdentifier}
        name={camilize(name)}
        placeholder={placeholder}
        component={InputField}
      />
    </div>
  );
};

export default Input;
