import React, { FunctionComponent } from 'react';
import { FormikHelpers } from 'formik';
import * as yup from 'yup';

import { QuestionnaireFormValues } from '../../../../types/components/index.d';

import { Form, Input } from '../../index';

type QuestionnaireFormProps = {
  onSubmit?: (values: QuestionnaireFormValues) => void;
};

type QuestionnaireFormFieldProps = {
  name: string;
  label: string;
  required?: boolean;
};

const FormField: FunctionComponent<QuestionnaireFormFieldProps> = ({
  name,
  label,
  required = false,
}) => (
  <div className="col-12 col-md-6">
    <Input name={name} label={label} required={required} />
  </div>
);

const QuestionnaireForm: FunctionComponent<QuestionnaireFormProps> = ({ onSubmit }) => {
  const initialValues: QuestionnaireFormValues = {
    fullName: '',
    advisorEmail: '',
    email: '',
  };

  const validationSchema = yup.object().shape({
    fullName: yup.string().required('Required'),
    advisorEmail: yup.string().email('Please enter a valid email address.'),
    email: yup.string().required('Required').email('Please enter a valid email address.'),
  });

  const handleSubmission = (
    values: QuestionnaireFormValues,
    { setSubmitting }: FormikHelpers<QuestionnaireFormValues>,
  ): void => {
    setSubmitting(false);

    if (onSubmit) {
      onSubmit(values);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmission}
    >
      <div className="row">
        <FormField name="fullName" label="Your name, first and last" required />
        <FormField name="advisorEmail" label="Your financial advisor email:" />
        <FormField name="email" label="Your email address" required />
      </div>

      <div className="row">
        <div className="col-xs-12">
          <input type="Submit" className="mt-4" value="Submit" />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xs-12">
          <p>
            By clicking submit, you agree that SEI Investments Management Company (SEI/we) is
            collecting personal data as defined in data protection laws including your name, your
            email address, and the email address of the recipient for the purposes of sharing this
            completed Risk Tolerance Questionnaire with your advisor to further discussions between
            you and your advisor. You are opting in to this consent to share this personal data with
            your advisor, which will be used for illustrative purposes only. We will retain this
            information for as long as we have a legitimate interest in processing your personal
            data. Your advisor is the data controller of the data submitted herein. Data Subject
            Access Requests should be submitted to the data controller.
          </p>
        </div>
      </div>
    </Form>
  );
};

export default QuestionnaireForm;
