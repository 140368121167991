import React, { FunctionComponent, useEffect } from 'react';
import { DragDropContext, DragStart, DragUpdate, DraggableLocation } from 'react-beautiful-dnd';
import MediaQuery from 'react-responsive';

import { quadrants, droppableIdToQuadrantId, modalData } from '../config';
import cardsData from '../../../data/cards';
import { Cards, Card } from '../../../types/components/index.d';
import { useMergeState } from '../../../hooks';
import LocalStorageService from '../../../services/localStorage';

import GameboardTray from '../Tray';
import GameboardQuadrant from '../Quadrant';
import Legend from '../Legend';
import { BlocksBottom, BlocksTop } from '../../Images';

import './styles.scss';

type GameboardProps = {
  printView?: boolean;
  onSave?: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Gameboard: FunctionComponent<GameboardProps> = ({ printView, onSave }) => {
  const localStorageService = new LocalStorageService();

  const defaultBoard = {
    default: cardsData,
    custom: [],
    wantToNow: [],
    wantToLater: [],
    haveToNow: [],
    haveToLater: [],
    skipped: [],
  };

  const initialState = {
    // TODO: Temp data
    cards: defaultBoard,
    draggingCard: null,
    isPrintModalOpen: false,
  };

  const [state, setState] = useMergeState(initialState);
  const { cards, draggingCard } = state;

  // Set the cards data on load. TODO: Need to add logic to load data from JSON and/or saved data
  useEffect(() => {
    const savedCards = localStorage.getItem('board');

    if (savedCards) {
      const parsedSavedCards = JSON.parse(savedCards);

      setState({
        cards: parsedSavedCards,
      });
    }
  }, [setState]);

  const reorder = (list: Card[], startIndex: number, endIndex: number): Card[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (
    source: Card[],
    destination: Card[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation,
  ): { [key: string]: Card[] } => {
    const sourceId = droppableIdToQuadrantId[droppableSource.droppableId];
    const destinationId = droppableIdToQuadrantId[droppableDestination.droppableId];
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);

    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {
      [sourceId]: sourceClone,
      [destinationId]: destClone,
    };

    return result;
  };

  const reorderCards = (source: DraggableLocation, destination: DraggableLocation): Cards => {
    const items = reorder(cards[source.droppableId], source.index, destination.index);

    return {
      ...cards,
      [source.droppableId]: items,
    };
  };

  const moveCards = (source: DraggableLocation, destination: DraggableLocation): Cards => {
    const sourceId = droppableIdToQuadrantId[source.droppableId];
    const destinationId = droppableIdToQuadrantId[destination.droppableId];
    const result = move(cards[sourceId], cards[destinationId], source, destination);
    return {
      ...cards,
      ...result,
    };
  };

  const updateCards = (source: DraggableLocation, destination: DraggableLocation): Cards => {
    const sourceId = droppableIdToQuadrantId[source.droppableId];
    const destinationId = droppableIdToQuadrantId[destination.droppableId];

    return sourceId === destinationId
      ? reorderCards(source, destination)
      : moveCards(source, destination);
  };

  const saveCustomCard = (card: Card): void => {
    setState({
      cards: {
        ...cards,
        default: [...cards.default, card],
        custom: [...cards.custom, card],
      },
    });

    localStorageService.set('board', {
      ...cards,
      default: [...cards.default, card],
      custom: [...cards.custom, card],
    });
  };

  const skipCard = (sourceLocation?: string, sourceIndex?: number): void => {
    const destination = sourceLocation === 'default' ? 'skipped' : 'default';

    const updatedCards = moveCards(
      {
        index: sourceIndex || 0,
        droppableId: sourceLocation || 'default',
      },
      {
        droppableId: destination,
        index: 0,
      },
    );

    setState({ cards: updatedCards });

    localStorageService.set('board', updatedCards);
  };

  const onDragStart = (result: DragStart): void => {
    const { source } = result;

    if (source && source.droppableId) {
      setState({ draggingCard: source.droppableId });
    }
  };

  const onDragEnd = (result: DragUpdate): void => {
    const { source, destination } = result;

    setState({ draggingCard: null });

    if (destination) {
      const updatedCards = updateCards(source, destination);

      setState({ cards: updatedCards });

      localStorageService.set('board', updatedCards);
    }
  };

  const resetBoard = (): void => {
    setState({
      cards: {
        ...defaultBoard,
        default: [...defaultBoard.default, ...cards.custom],
        custom: [...cards.custom], // Need to save for tracking purposes. Esp on page refresh
      },
    });

    localStorageService.set('board', {
      ...defaultBoard,
      default: [...defaultBoard.default, ...cards.custom],
      custom: [...cards.custom],
    });
  };

  // For mobile modal only
  const unplaceCard = (card: Card, listName: string): void => {
    const source = {
      droppableId: listName,
      index: cards[listName].findIndex((item: Card) => item.id === card.id),
    };

    const destination = {
      droppableId: 'default',
      index: cards.default.length,
    };

    const updatedCards = moveCards(source, destination);

    setState({ cards: updatedCards });

    localStorageService.set('board', updatedCards);
  };

  // For mobile modal only
  const placeCard = (card: Card, listName: string): void => {
    const source = {
      droppableId: 'default',
      index: cards.default.findIndex((item: Card) => item.id === card.id),
    };

    const destination = {
      droppableId: listName,
      index: cards[listName].length,
    };

    const updatedCards = moveCards(source, destination);

    setState({ cards: updatedCards });

    localStorageService.set('board', updatedCards);
  };

  // Print function
  const openPrintView = (): void => {
    window.open(`/print`, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      {printView ? (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <div className="container mt-5">
            <div className="gameboard-container gameboard-container--print">
              <div className="row">
                <div className="col-12">
                  <div className="gameboard h-100">
                    <div className="w-100 d-flex flex-wrap position-relative" style={{ top: '0' }}>
                      {quadrants.map((quadrant, i) => (
                        <GameboardQuadrant
                          key={quadrant.id}
                          cardItems={cards[quadrant.id]}
                          listName={quadrant.id}
                          isMoving={draggingCard === quadrant.id}
                          modalHeader={modalData[i].modalHeader}
                          modalBody={modalData[i].modalBody}
                          label={quadrant.label}
                          description={quadrant.text}
                          onPlaceCard={placeCard}
                          onUnplaceCard={unplaceCard}
                          onSkip={skipCard}
                        />
                      ))}

                      <h3 className="gameboard__axis gameboard__axis--top h4">Want to</h3>
                      <h3 className="gameboard__axis gameboard__axis--right h4">Later</h3>
                      <h3 className="gameboard__axis gameboard__axis--bottom h4">Have to</h3>
                      <h3 className="gameboard__axis gameboard__axis--left h4">Now</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DragDropContext>
      ) : (
        <MediaQuery minWidth={1024}>
          <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <div className="container mt-5">
              <BlocksTop />

              <div className="gameboard-container">
                <div className="row">
                  <div className="col-4 mt-4">
                    <div className="gameboard__column-container">
                      <GameboardTray cards={cards} onSave={saveCustomCard} onSkip={skipCard} />
                      <Legend />
                      <div className="d-flex justify-content-center align-items-center mt-3">
                        {/* <button type="button" className="btn btn--dark" onClick={onSave}>
                          Save board
                        </button> */}

                        <div className="ms-2 small">
                          {/* <span className="d-inline-block">or,</span> */}
                          <button
                            type="button"
                            className="link link--primary ms-1 text-decoration-underline fw-bold"
                            onClick={resetBoard}
                          >
                            Reset board.
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center mt-3">
                      <button type="button" className="btn btn--dark" onClick={openPrintView}>
                        Print board.
                      </button>
                    </div>
                  </div>

                  <div className="col-12 col-lg-8">
                    <div className="gameboard h-100">
                      <div
                        className="w-100 d-flex flex-wrap position-relative"
                        style={{ top: '0' }}
                      >
                        {quadrants.map((quadrant, i) => (
                          <GameboardQuadrant
                            key={quadrant.id}
                            cardItems={cards[quadrant.id]}
                            listName={quadrant.id}
                            isMoving={draggingCard === quadrant.id}
                            modalHeader={modalData[i].modalHeader}
                            modalBody={modalData[i].modalBody}
                            label={quadrant.label}
                            description={quadrant.text}
                            onPlaceCard={placeCard}
                            onUnplaceCard={unplaceCard}
                            onSkip={skipCard}
                          />
                        ))}

                        <h3 className="gameboard__axis gameboard__axis--top h4">Want to</h3>
                        <h3 className="gameboard__axis gameboard__axis--right h4">Later</h3>
                        <h3 className="gameboard__axis gameboard__axis--bottom h4">Have to</h3>
                        <h3 className="gameboard__axis gameboard__axis--left h4">Now</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <BlocksBottom />
            </div>
          </DragDropContext>
        </MediaQuery>
      )}

      {!printView && (
        <>
          <MediaQuery minWidth={600} maxWidth={1023}>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <div className="container mt-5">
                <div className="gameboard">
                  <div className="row">
                    <div className="col-6 offset-3">
                      <GameboardTray cards={cards} onSave={saveCustomCard} onSkip={skipCard} />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="gameboard d-flex flex-wrap">
                        {quadrants.map((quadrant, i) => (
                          <GameboardQuadrant
                            key={quadrant.id}
                            cardItems={cards[quadrant.id]}
                            listName={quadrant.id}
                            isMoving={draggingCard === quadrant.id}
                            modalHeader={modalData[i].modalHeader}
                            modalBody={modalData[i].modalBody}
                            label={quadrant.label}
                            description={quadrant.text}
                            onPlaceCard={placeCard}
                            onUnplaceCard={unplaceCard}
                            onSkip={skipCard}
                          />
                        ))}

                        <h3 className="gameboard__axis gameboard__axis--top h4">Want to</h3>
                        <h3 className="gameboard__axis gameboard__axis--right h4">Later</h3>
                        <h3 className="gameboard__axis gameboard__axis--bottom h4">Have to</h3>
                        <h3 className="gameboard__axis gameboard__axis--left h4">Now</h3>
                      </div>
                    </div>
                  </div>
                  <Legend />
                  <div className="d-flex flex-column align-items-center mt-3">
                    {/* <button type="button" className="btn btn--dark" onClick={onSave}>
                      Save board
                    </button> */}

                    <div className="mt-2 small">
                      {/* <span className="d-inline-block">or,</span> */}
                      <button
                        type="button"
                        className="link link--primary ms-1 text-decoration-underline fw-bold"
                        onClick={resetBoard}
                      >
                        Reset board.
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </DragDropContext>
          </MediaQuery>

          <MediaQuery maxWidth={599}>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <div className="container mt-5">
                <div className="gameboard">
                  <div className="row">
                    <div className="col-12 p-0">
                      <GameboardTray cards={cards} onSave={saveCustomCard} onSkip={skipCard} />
                      <Legend />
                      <div className="mt-3 px-3 d-flex flex-wrap">
                        {quadrants.map((quadrant, i) => (
                          <GameboardQuadrant
                            key={quadrant.id}
                            cardItems={cards[quadrant.id]}
                            listName={quadrant.id}
                            isMoving={draggingCard === quadrant.id}
                            modalHeader={modalData[i].modalHeader}
                            modalBody={modalData[i].modalBody}
                            label={quadrant.label}
                            description={quadrant.text}
                            onPlaceCard={placeCard}
                            onUnplaceCard={unplaceCard}
                            onSkip={skipCard}
                          />
                        ))}
                      </div>

                      <div className="d-flex flex-column align-items-center mt-3">
                        {/* <button type="button" className="btn btn--dark" onClick={onSave}>
                          Save board
                        </button> */}

                        <div className="mt-2 small">
                          {/* <span className="d-inline-block">or,</span> */}
                          <button
                            type="button"
                            className="link link--primary ms-1 text-decoration-underline fw-bold"
                            onClick={resetBoard}
                          >
                            Reset board.
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DragDropContext>
          </MediaQuery>
        </>
      )}
    </>
  );
};

export default Gameboard;
