import React, { FunctionComponent, MouseEvent } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';

import { Cards, Card, CustomCardValues } from '../../../types/components/index.d';
import { useMergeState } from '../../../hooks';

import { CustomCard } from '../../Form/Types';
import ScrollFade from '../../ScrollFade';
import Toggle from '../Toggle';
import GameboardList from '../List';
import GameboardCardSlider from '../CardSlider';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

type GameboardTrayProps = {
  className?: string;
  cards: Cards;
  onSave: (card: Card) => void;
  onSkip: (location?: string, index?: number) => void;
};

const GameboardTray: FunctionComponent<GameboardTrayProps> = ({
  className,
  cards,
  onSave,
  onSkip,
}) => {
  const initialState = {
    activeToggle: 0,
    activeCardIndex: 0,
    customCardModalIsOpen: false,
  };

  const [state, setState] = useMergeState(initialState);
  const { activeToggle, activeCardIndex, customCardModalIsOpen } = state;

  const tray = cards.default || [];
  const skipped = cards.skipped || [];

  const toggle = (e?: MouseEvent, n?: number): void => {
    // Reset active card index
    setState({ activeCardIndex: 0 });

    if (n) {
      setState({ activeToggle: n });
      return;
    }

    if (activeToggle === 0) {
      setState({ activeToggle: 1 });
    } else {
      setState({ activeToggle: 0 });
    }
  };

  const toggleCustomCardModal = (): void => {
    setState((prevState: Record<string, unknown>) => ({
      customCardModalIsOpen: !prevState.customCardModalIsOpen,
    }));
  };

  const saveCustomCard = (values: CustomCardValues): void => {
    const customCardId =
      cards.default.length +
      cards.wantToNow.length +
      cards.wantToLater.length +
      cards.haveToNow.length +
      cards.haveToLater.length +
      cards.skipped.length +
      1;

    onSave({
      id: customCardId,
      label: values.customCard,
      active: false,
    });
  };

  const skipCard = (): void => {
    const source = activeToggle !== 0 ? 'skipped' : 'default';

    onSkip(source, activeCardIndex);
  };

  const activeToggleListLength = (): number => {
    if (activeToggle === 0) {
      return tray.length;
    }

    return skipped.length;
  };

  const hasNoSkippedCards = (): boolean => activeToggle === 1 && skipped.length === 0;

  const updateActiveCard = (index: number): void => setState({ activeCardIndex: index });

  return (
    <div className={classnames('gameboard-tray__container', className)}>
      <MediaQuery minWidth={1201}>
        <ScrollFade>
          <div className="gameboard-tray">
            <div className="mb-3">
              <CustomCard
                isOpen={customCardModalIsOpen}
                toggleModal={toggleCustomCardModal}
                onSave={saveCustomCard}
              />
            </div>

            <Toggle
              toggleIndex={activeToggle}
              text1={`Life Goals (${tray.length})`}
              text2={`Skipped Goals (${skipped.length})`}
              onToggle={toggle}
            />

            <div className="gameboard-tray__container pb-5">
              {activeToggle === 0 ? (
                <div className="h-100">
                  {tray.length > 0 ? (
                    <GameboardList items={tray} listName="default" onSkip={onSkip} />
                  ) : (
                    <div>
                      <Droppable droppableId="tray">
                        {(provided, snapshot) => (
                          <div
                            className={classnames('gameboard__list', {
                              'is-dragging': snapshot.isDraggingOver,
                            })}
                          >
                            <div className="gameboard__cards" ref={provided.innerRef}>
                              <div className="gameboard-tray__empty">
                                <p className="gameboard-tray__empty- m-0">Out of cards?</p>
                                <button
                                  type="button"
                                  className="gameboard__card-action link link--primary"
                                  onClick={(event) => toggle(event, 1)}
                                >
                                  See Skipped Cards
                                </button>
                              </div>

                              {provided.placeholder}
                            </div>
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )}
                </div>
              ) : (
                <GameboardList items={skipped} listName="skipped" onSkip={onSkip} />
              )}
            </div>
          </div>
        </ScrollFade>
      </MediaQuery>

      <MediaQuery minWidth={600} maxWidth={1200}>
        <div className="text-center">
          <div className="mb-3">
            <CustomCard
              isOpen={customCardModalIsOpen}
              toggleModal={toggleCustomCardModal}
              onSave={saveCustomCard}
            />
          </div>

          <Toggle
            className="mb-0"
            toggleIndex={activeToggle}
            text1={`Goals (${tray.length})`}
            text2={`Skipped (${skipped.length})`}
            onToggle={toggle}
          />

          {activeToggleListLength() > 0 ? (
            <>
              <GameboardCardSlider
                items={activeToggle === 0 ? tray : skipped}
                listName={activeToggle === 0 ? 'default' : 'skipped'}
                onUpdate={updateActiveCard}
              />
              <button
                type="button"
                className="gameboard__card-action link link--primary mb-2"
                onClick={skipCard}
              >
                {activeToggle === 0 ? 'Skip Card' : 'Return to Deck'}
              </button>
            </>
          ) : (
            <div style={{ height: '80px' }}>
              <Droppable droppableId={activeToggle === 0 ? 'skipped' : 'default'}>
                {(provided, snapshot) => (
                  <div className={classnames({ 'is-dragging': snapshot.isDraggingOver })}>
                    <div className="gameboard__slider-empty" ref={provided.innerRef}>
                      <p className="mb-0">Out of cards?</p>
                      {!hasNoSkippedCards() && (
                        <button
                          type="button"
                          className="gameboard__card-action link link--primary"
                          onClick={toggle}
                        >
                          See {activeToggle === 0 ? 'Skipped' : 'Life Goal'} Cards
                        </button>
                      )}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            </div>
          )}
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={599}>
        <div className="text-center">
          <div className="mb-3">
            <CustomCard
              isOpen={customCardModalIsOpen}
              toggleModal={toggleCustomCardModal}
              onSave={saveCustomCard}
            />
          </div>

          <Toggle
            className="mb-0"
            toggleIndex={activeToggle}
            text1={`Goals (${tray.length})`}
            text2={`Skipped (${skipped.length})`}
            onToggle={toggle}
          />

          <GameboardCardSlider
            items={activeToggle === 0 ? tray : skipped}
            listName={activeToggle === 0 ? 'default' : 'skipped'}
            onUpdate={updateActiveCard}
          />
          {!hasNoSkippedCards() && (
            <button
              type="button"
              className="gameboard__card-action link link--primary mb-2"
              onClick={skipCard}
            >
              {activeToggle === 0 ? 'Skip Card' : 'Return to Deck'}
            </button>
          )}
        </div>
      </MediaQuery>
    </div>
  );
};

export default GameboardTray;
