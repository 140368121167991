import React, { FunctionComponent } from 'react';

import { Line, House, Box, Suitcase } from '../../Images';

import '../styles.scss';

type HomeHeroProps = {
  onClick: () => void;
};

const HomeHero: FunctionComponent<HomeHeroProps> = ({ onClick }) => (
  <div className="hero hero--home">
    <div className="hero__content">
      <h1 className="text-center mb-4 mb-md-2">A financial strategy, built around you.</h1>
      <p className="text-center h4 mb-4 mb-md-4">
        Use our interactive discovery board to identify and prioritize your goals.
      </p>

      <div className="hero__icon-row mb-5">
        <div className="hero__icon">
          <House />
        </div>
        <div className="hero__icon mx-4">
          <Line />
        </div>
        <div className="hero__icon">
          <Box />
        </div>
        <div className="hero__icon mx-4">
          <Line />
        </div>
        <div className="hero__icon">
          <Suitcase />
        </div>
      </div>

      <div className="text-center">
        <button className="btn btn--dark" type="button" onClick={onClick}>
          Get started
        </button>
      </div>
    </div>
  </div>
);

export default HomeHero;
