import React, { FunctionComponent } from 'react';

const RiskToleranceHero: FunctionComponent = () => (
  <div className="hero hero--risk-tolerance">
    <div className="hero__content">
      <h1 className="text-center mb-4 mb-md-2">Risk Tolerance Questionnaire</h1>
      <p className="text-center h4 mb-4 mb-md-4">
        Four elements to help you arrive at a suitable investment strategy for your goals:
      </p>

      <div className="hero__headline row">
        <div className="hero__headline-item bg-orange-light col-6 col-sm-3">
          <h4 className="text-center">Risk tolerance</h4>
        </div>
        <div className="hero__headline-item bg-green-light col-6 col-sm-3">
          <h4 className="text-center">Investment time horizon</h4>
        </div>
        <div className="hero__headline-item bg-pink col-6 col-sm-3">
          <h4 className="text-center">Tax sensitivity</h4>
        </div>
        <div className="hero__headline-item bg-blue-light-xxx col-6 col-sm-3">
          <h4 className="text-center">Investment objective</h4>
        </div>
      </div>
    </div>
  </div>
);

export default RiskToleranceHero;
