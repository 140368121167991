import React, { FunctionComponent } from 'react';
import { Goal, Leaf, Cards } from '../Images';
import './styles.scss';

const FeatureCallout: FunctionComponent = () => (
  <div className="feature-callout">
    <div className="container">
      <div className="row">
        <div className="col-md-4 col-sm-12 text-center">
          <div className="svg-wrapper">
            <Goal />
          </div>
          <h3 className="feature-callout__heading">Goals-based framework</h3>
          <p className="mt-3">
            Begin by identifying and prioritizing your goals and uncovering any biases that might
            stand in your way.
          </p>
        </div>
        <div className="col-md-4 col-sm-12 text-center">
          <div className="svg-wrapper">
            <Leaf />
          </div>
          <h3 className="feature-callout__heading">Personalized approach</h3>
          <p className="mt-3">
            Select from a list of common goals, or customize your own goals, to learn what’s most
            meaningful to you.
          </p>
        </div>
        <div className="col-md-4 col-sm-12 text-center">
          <div className="svg-wrapper">
            <Cards />
          </div>
          <h3 className="feature-callout__heading">Visualize to prioritize</h3>
          <p className="mt-3">
            Lay all your cards on the table. Interacting with your goals will lead to better
            conversations that you didn’t know were possible.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default FeatureCallout;
