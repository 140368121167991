import React, { FunctionComponent } from 'react';
import { FormikHelpers } from 'formik';
import * as yup from 'yup';
import jQuery from 'jquery';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useMergeState } from '../../../../hooks';
import { ContactFormValues } from '../../../../types/components/index.d';

import { Radio, Form, Input, TextArea } from '../../index';

const ELOQUA_URL = 'https://s1281045713.t.eloqua.com/e/f2.aspx';

type ContactFormProps = {
  onSubmit?: (values: ContactFormValues) => void;
};

type ContactFormFieldProps = {
  name: string;
  label: string;
  required?: boolean;
};

const FormField: FunctionComponent<ContactFormFieldProps> = ({ name, label, required = false }) => (
  <div className="col-12 col-md-6">
    <Input name={name} label={label} required={required} />
  </div>
);

const ContactForm: FunctionComponent<ContactFormProps> = () => {
  const initialValues: ContactFormValues = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    busPhone: '',
    shareGoals: '',
    role: '',
  };

  const initialState = {
    formSuccess: null,
    roleError: false,
  };

  const formInfo = {
    elqFormName: 'ADV_FORMD_2111_GameBoardConnectwithUs',
    elqSiteId: 1281045713,
  };

  const location = useLocation();
  const search = location.search.substring(1);

  const [state, setState] = useMergeState(initialState);

  const { formSuccess, roleError } = state;

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    busPhone: yup.string().matches(phoneRegExp, 'Phone number is not valid').min(9),
    emailAddress: yup.string().required('Required').email(),
  });

  const handleSubmission = async (
    values: ContactFormValues,
    { setSubmitting }: FormikHelpers<ContactFormValues>,
  ): Promise<unknown> => {
    const urlParams = search
      ? JSON.parse(`{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) => {
          return key === '' ? value : decodeURIComponent(value);
        })
      : {};
    setSubmitting(false);

    if (!values.role) {
      setState({
        roleError: true,
      });
      return;
    }
    setState({
      roleError: false,
    });

    const data = {
      ...formInfo,
      ...values,
      ...urlParams,
    };

    jQuery.ajax({
      type: 'GET',
      url: `${ELOQUA_URL}?${queryString.stringify(data)}`,
      success: () => {
        setState({ formSuccess: true });
      },
    });
  };

  return (
    <>
      {!formSuccess ? (
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmission}
        >
          <div className="row">
            <FormField name="firstName" label="First Name" required />
            <FormField name="lastName" label="Last Name" required />
            <FormField name="emailAddress" label="Email Address" required />
            <FormField name="busPhone" label="Business Phone" />
            <div className="col-12 col-md-6">
              <div role="radiogroup" aria-labelledby="role" aria-describedby="role">
                <p className="label" id="role_label">
                  Role
                </p>
                <Radio name="role" label=" Financial Advisor" value="Financial Advisor" />
                <Radio name="role" label="Investor" value="Investor" />
                <span className={classnames('error', roleError ? 'show' : 'd-none')}>Required</span>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <TextArea name="shareGoals" label="Additional Details" />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <input type="Submit" className="mt-2" value="Submit" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-xs-12">
              <p>
                I understand SEI may send future emails to me, even if I opted-out before, and that
                I can opt-out again later.
              </p>
            </div>
          </div>
        </Form>
      ) : (
        <h3 className="text-center mt-5">Thanks. We&apos;ll be in touch shortly.</h3>
      )}
    </>
  );
};

export default ContactForm;
