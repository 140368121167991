import React, { FunctionComponent, MouseEvent } from 'react';
import classNames from 'classnames';

import './styles.scss';

type ToggleProps = {
  className?: string;
  text1: string;
  text2: string;
  toggleIndex?: number;
  onToggle: (event: MouseEvent, index: number) => void;
};

const Toggle: FunctionComponent<ToggleProps> = ({
  className,
  text1,
  text2,
  toggleIndex,
  onToggle,
}) => (
  <div className={classNames('toggle-container', className)}>
    <button
      type="button"
      className={`btn toggle-container__item--left ${
        toggleIndex === 0 ? 'toggle-container__active' : ''
      }`}
      onClick={(e: MouseEvent) => onToggle(e, 0)}
    >
      {text1 || ''}
    </button>
    <button
      type="button"
      className={`btn toggle-container__item--right ${
        toggleIndex ? 'toggle-container__active' : ''
      }`}
      onClick={(e: MouseEvent) => onToggle(e, 1)}
    >
      {text2 || ''}
    </button>

    <div
      className={`toggle-container__highlight ${
        toggleIndex === 0
          ? 'toggle-container__highlight--left'
          : 'toggle-container__highlight--right'
      }`}
    />
  </div>
);

export default Toggle;
