import React, { FunctionComponent } from 'react';

import Page from '../../../components/Page';
import { RiskToleranceHero } from '../../../components/Hero';
import Questionnaire from '../../../components/Questionnaire/Container';

const headerLink = {
  link: '/',
  label: 'Back to Gameboard',
};

const RiskToleranceQuestionnaire: FunctionComponent = () => (
  <Page
    headerLink={headerLink}
    description="Example metadata for Risk Tolerance Questionnaire page"
  >
    <RiskToleranceHero />

    <Questionnaire />
  </Page>
);

export default RiskToleranceQuestionnaire;
