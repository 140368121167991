import React, { FunctionComponent, useEffect } from 'react';

import Header from '../../components/Header';
import { Gameboard } from '../../components/Gameboard';
import Footer from '../../components/Footer';

const PrintPage: FunctionComponent = () => {
  useEffect(() => {
    setTimeout(() => window.print(), 1000);
  }, []);

  return (
    <>
      <Header printView />
      <div className="gameboard-wrapper bg-gray">
        <Gameboard printView />
      </div>
      <Footer />
    </>
  );
};

export default PrintPage;
