import React, { FunctionComponent } from 'react';
import { Field } from 'formik';
import classnames from 'classnames';

import { Card } from '../../../types/components/index.d';
import { camilize } from '../helper';

import '../styles.scss';

type CheckboxProps = {
  name: string;
  label: string;
  labelClassName?: string;
  checked: boolean;
  onChange?: (e: React.FormEventHandler<HTMLInputElement>, card: Card) => void;
};

const Checkbox: FunctionComponent<CheckboxProps> = ({
  name,
  label,
  labelClassName,
  checked,
  onChange,
}) => {
  const uniqueIdentifier = name.replace(' ', '-').toLowerCase();

  return (
    <div className="checkbox mb-2">
      <Field
        type="checkbox"
        className="u-sr-only"
        id={uniqueIdentifier}
        name={camilize(name)}
        checked={checked}
        onChange={onChange}
      />

      <label htmlFor={uniqueIdentifier} className={classnames('checkbox__label', labelClassName)}>
        {label}
      </label>

      <div
        className={classnames('checkbox__custom-input', checked && 'is-checked')}
        aria-hidden="true"
        role="presentation"
      />
    </div>
  );
};

export default Checkbox;
