import React, { FunctionComponent } from 'react';

const Disclosure: FunctionComponent = () => (
  <ul className="list-unstyled">
    <li>
      The SEI Discovery Board and Risk Tolerance Questionnaire are the business names for the Goals
      Based Discovery interactive tools provided by SEI Investments Management Corporation (SIMC), a
      registered investment advisor. Usage of these tools is for illustrative purposes only and does
      not constitute advice or a recommendation. SIMC is a wholly owned subsidiary of SEI
      Investments Company.
    </li>
    <li className="mt-3">
      If Risk Tolerance is required by SEI, we have incorporated the Risk Tolerance Questionnaire
      within the Account Application. This tool will not be accepted in lieu of the required Risk
      Tolerance Questionnaire contained within the Account Application.
    </li>
    <li className="mt-3">
      Investing involves risk including possible loss of principal. There can be no guarantee
      objectives will be achieved. Due to the ever changing nature of investing, be sure to review
      your investment decisions periodically to be sure they are still consistent with your goals.
    </li>
    <li className="mt-3">Your financial advisor is not affiliated with SEI or its subsidiaries</li>
  </ul>
);

export default Disclosure;
