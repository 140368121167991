import React, { useState, useEffect, FunctionComponent, Fragment } from 'react';

import { Card, MobileMenuValues } from '../../../types/components/index.d';
import { Form, Checkbox } from '../../Form';

type GameboardMobileMenuProps = {
  cardItems: Card[];
  listName: string;
  onPlaceCard: (card: Card, listName: string) => void;
  onUnplaceCard: (card: Card, listName: string) => void;
};

const GameboardMobileMenu: FunctionComponent<GameboardMobileMenuProps> = ({
  cardItems,
  listName,
  onPlaceCard,
  onUnplaceCard,
}) => {
  const [cardsList, setCardsList] = useState<Card[]>([]);

  const initialValues: MobileMenuValues = {
    cards: [],
  };

  useEffect(() => {
    const cards = cardItems.map((card) => ({
      ...card,
      active: true,
    }));

    setCardsList(cards);
    // eslint-disable-next-line
  }, []);

  const handleChange = (e: React.FormEventHandler<HTMLInputElement>, card: Card): void => {
    const newCards = cardsList.map((item) => {
      if (item.id === card.id) {
        return {
          ...card,
          active: !card.active,
        };
      }

      return item;
    });

    setCardsList(newCards);

    if (card.active) {
      onUnplaceCard(card, listName);
    } else {
      onPlaceCard(card, listName);
    }
  };

  return (
    <>
      <Form initialValues={initialValues} onSubmit={handleChange}>
        {cardsList.map((card) => (
          <Fragment key={card.id}>
            <Checkbox
              name={`card-${card.id}`}
              label={card.label}
              checked={card.active}
              onChange={(e: React.FormEventHandler<HTMLInputElement>) => handleChange(e, card)}
            />
          </Fragment>
        ))}
      </Form>

      {!cardsList.length && (
        <div>
          <h3>You have not placed any cards in this section.</h3>
        </div>
      )}
    </>
  );
};

export default GameboardMobileMenu;
