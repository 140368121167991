export default [
  {
    id: 1,
    label: 'Retirement',
    category: 'financial',
  },
  {
    id: 2,
    label: 'Education',
    category: 'financial',
  },
  {
    id: 3,
    label: 'Major purchase',
    category: 'financial',
  },
  {
    id: 4,
    label: 'Emergency fund',
    category: 'financial',
  },
  {
    id: 5,
    label: 'Home purchase',
    category: 'financial',
  },
  {
    id: 6,
    label: 'Second home purchase',
    category: 'financial',
  },
  {
    id: 7,
    label: 'Travel',
    category: 'financial',
  },
  {
    id: 8,
    label: 'Charitable giving',
    category: 'financial',
  },
  {
    id: 9,
    label: 'Income',
    category: 'financial',
  },
  {
    id: 10,
    label: 'Help family member(s)',
    category: 'financial',
  },
  {
    id: 11,
    label: 'Long-term care',
    category: 'financial',
  },
  {
    id: 12,
    label: 'Car purchase',
    category: 'financial',
  },
  {
    id: 13,
    label: 'Start a business',
    category: 'financial',
  },
  {
    id: 14,
    label: 'Wedding',
    category: 'financial',
  },
  {
    id: 15,
    label: 'Home improvement',
    category: 'financial',
  },
  {
    id: 16,
    label: 'Insurance policy premium',
    category: 'financial',
  },
  {
    id: 17,
    label: 'Alimony',
    category: 'financial',
  },
  {
    id: 18,
    label: 'Child support',
    category: 'financial',
  },
  {
    id: 19,
    label: 'Gift',
    category: 'financial',
  },
  {
    id: 20,
    label: 'Organize finances',
    category: 'life',
  },
  {
    id: 21,
    label: 'Spend more time with family',
    category: 'life',
  },
  {
    id: 22,
    label: 'Improve lifestyle',
    category: 'life',
  },
  {
    id: 23,
    label: 'Scale back at work',
    category: 'life',
  },
  {
    id: 24,
    label: 'Increase community involvement',
    category: 'life',
  },
  {
    id: 25,
    label: 'Find a new hobby',
    category: 'life',
  },
  {
    id: 26,
    label: 'Help children learn about finances',
    category: 'life',
  },
  {
    id: 27,
    label: 'Sell a business',
    category: 'life',
  },
  {
    id: 28,
    label: `Determine what's enough`,
    category: 'life',
  },
  {
    id: 29,
    label: 'Pursue other income sources',
    category: 'life',
  },
  {
    id: 30,
    label: 'Change career',
    category: 'life',
  },
  {
    id: 31,
    label: 'Focus on physical fitness',
    category: 'life',
  },
  {
    id: 32,
    label: 'Move to a new place',
    category: 'life',
  },
];
