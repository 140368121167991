import React, { FunctionComponent, useRef, useState } from 'react';

import Page from '../../components/Page';
import Modal from '../../components/Modal';
import { ContactForm } from '../../components/Form/Types';
import { HomeHero } from '../../components/Hero';
import FeatureCallout from '../../components/FeatureCallout';
import { GameboardHeader, Gameboard } from '../../components/Gameboard';
import InfoBlocks from '../../components/InfoBlocks';
import FooterCTA from '../../components/FooterCTA';

import './styles.scss';

const HomePage: FunctionComponent = () => {
  const gameboardRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const scrollToGameboard = (): void => {
    if (gameboardRef?.current) {
      window.scrollTo(0, gameboardRef?.current?.offsetTop - 100);
    }
  };

  const toggleModal = (): void => setIsModalOpen(!isModalOpen);

  return (
    <Page description="Example metadata for Home page">
      <HomeHero onClick={scrollToGameboard} />
      <FeatureCallout />
      <div ref={gameboardRef} className="gameboard-wrapper bg-aqua">
        <GameboardHeader />
        <Gameboard onSave={toggleModal} />
      </div>
      <InfoBlocks />
      <FooterCTA />

      <Modal
        header="Let’s save your gameboard progress."
        isOpen={isModalOpen}
        closeModal={toggleModal}
      >
        <p className="mb-3 modal__lede text-center">
          Schedule a one-on-one demo call with our SEI experts to learn about the amazing features,
          have all your burning questions answered, and get started in no time!
        </p>

        <ContactForm />
      </Modal>
    </Page>
  );
};

export default HomePage;
