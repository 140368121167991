import React, { FunctionComponent } from 'react';
import ReactHtmlParser from 'react-html-parser';
import classNames from 'classnames';
import { useMergeState } from '../../../hooks';
import GameboardList from '../List';
import { Kebab } from '../../Images';

import { Card } from '../../../types/components/index.d';

import '../Container/styles.scss';
import Modal from '../../Modal';
import MobileMenu from '../MobileMenu';

type GameboardQuadrantProps = {
  cardItems: Card[];
  listName: string;
  label: string;
  modalHeader: string;
  modalBody: string;
  isMoving: boolean;
  description: string;
  onPlaceCard: (card: Card, listName: string) => void;
  onUnplaceCard: (card: Card, listName: string) => void;
  onSkip?: (sourceLocation?: string, sourceIndex?: number) => void;
};

const GameboardQuadrant: FunctionComponent<GameboardQuadrantProps> = ({
  cardItems,
  listName,
  isMoving,
  modalHeader,
  modalBody,
  label,
  onSkip,
  onPlaceCard,
  onUnplaceCard,
  description,
}) => {
  const initialState = {
    modalIsOpen: false,
    mobileModalIsOpen: false,
  };

  const [state, setState] = useMergeState(initialState);
  const { modalIsOpen, mobileModalIsOpen } = state;

  const toggleModal = (): void => {
    setState((prevState: Record<string, unknown>) => ({
      modalIsOpen: !prevState.modalIsOpen,
    }));
  };

  const toggleMobileModal = (): void => {
    setState((prevState: Record<string, unknown>) => ({
      mobileModalIsOpen: !prevState.mobileModalIsOpen,
    }));
  };

  return (
    <div
      className={classNames('gameboard__column', `gameboard__column--${listName}`, {
        'is-moving': isMoving,
      })}
    >
      <div className="gameboard__section">
        <div className="gameboard__title">
          {(modalHeader || modalBody) && (
            <span>
              {label}{' '}
              <span className="gameboard__section__help">
                (
                <span
                  className="gameboard-card__action--modal"
                  role="link"
                  tabIndex={0}
                  onClick={toggleModal}
                  onKeyPress={toggleModal}
                >
                  Help
                </span>
                )
              </span>
            </span>
          )}
        </div>

        <GameboardList items={cardItems} listName={listName} onSkip={onSkip} />

        <div className="gameboard__bg">
          <div
            className={classNames('gameboard__bg__content', {
              'gameboard__bg__content--has-cards': cardItems.length > 0,
            })}
          >
            <div className="gameboard__bg__title">{label}</div>
            <div
              className="gameboard__bg__count"
              style={{ opacity: cardItems.length === 0 ? 1 : 0.99 }}
            >
              {cardItems.length} Goal{cardItems.length === 1 ? '' : 's'}
            </div>
            <div className="gameboard__bg__text">{ReactHtmlParser(description)}</div>
          </div>
        </div>

        <div className="gameboard__menu-toggle">
          <span
            role="button"
            tabIndex={0}
            onClick={toggleMobileModal}
            onKeyPress={toggleMobileModal}
          >
            <Kebab />
          </span>
        </div>
      </div>

      <Modal header={modalHeader} isOpen={modalIsOpen} closeModal={toggleModal}>
        {ReactHtmlParser(modalBody)}
      </Modal>
      <Modal header={modalHeader} isOpen={mobileModalIsOpen} closeModal={toggleMobileModal}>
        <MobileMenu
          cardItems={cardItems}
          listName={listName}
          onPlaceCard={onPlaceCard}
          onUnplaceCard={onUnplaceCard}
        />
      </Modal>
    </div>
  );
};

export default GameboardQuadrant;
