import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import './styles.scss';

type ScrollFadeProps = {
  className?: string;
};

const ScrollFade: FunctionComponent<ScrollFadeProps> = ({ className, children }) => (
  <div className="position-relative h-100 w-100">
    {children}

    <div className={classNames('scroll-fade', className)} />
  </div>
);

export default ScrollFade;
